<template>
  <el-form ref="editor" class="connector-detail" :model="model" :rules="rules" label-width="140px">
    <el-form-item label="数据源名称" prop="connectionName">
      <el-input v-model="model.connectionName" />
    </el-form-item>
    <el-form-item label="数据源连接类型" prop="connectionType">
      <el-input v-model="model.connectionType" />
    </el-form-item>
    <el-form-item label="连接地址" prop="connectionUrl">
      <el-input v-model="model.connectionUrl" />
    </el-form-item>
    <el-form-item label="端口号" prop="port">
      <el-input v-model="model.port" />
    </el-form-item>
    <el-form-item label="数据库名" prop="dbName">
      <el-input v-model="model.dbName" />
    </el-form-item>
    <el-form-item label="编码" prop="charset">
      <el-input v-model="model.charset" />
    </el-form-item>
    <el-form-item label="连接用户名" prop="username">
      <el-input v-model="model.username" />
    </el-form-item>
    <el-form-item label="连接密码" prop="password">
      <el-input v-model="model.password" />
    </el-form-item>
    <el-form-item label="备注" prop="remark">
      <el-input v-model="model.remark" />
    </el-form-item>
    <el-form-item>
      <el-button type="primary" size="small" @click="handleSubmit('editor')">
        确认
      </el-button>
      <el-button size="small" @click="handleCancel('editor')">
        取消
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { pick } from 'lodash';
import { resolveDefault } from '@/utils';
import { addDb, updateDb } from '@/api/finder';
function resolveActionModel (info) {
  const data = pick(info, [
    'id',
    'charset',
    'connectionName',
    'connectionType',
    'connectionUrl',
    'dbName',
    'password',
    'port',
    'remark',
    'username'
  ]);

  return resolveDefault({
    action: {},
    extra: {},
    isEnable: true,
    hasChildren: false,
    resourceType: 'menu'
  }, data);
  // console.log(info,'==', data)
  // return data
}

export default {
  // inject: ['$resources'],

  props: {
    info: {
      type: Object
    },
    data: {
      type: Array,
      default: () => []
    }
  },

  data () {
    return {
      model: {},
      rules: {
        connectionName: [
          { required: true, message: '请选输入数据源名称', trigger: 'blur' }
        ],
        connectionType: [
          { required: true, message: '请选输入数据源连接类型', trigger: 'blur' }
        ],
        connectionUrl: [
          { required: true, message: '请选输入连接地址', trigger: 'blur' }
        ],
        dbName: [
          { required: true, message: '请选输数据库名', trigger: 'blur' }
        ],
        charset: [
          { required: true, message: '请选输入编码', trigger: 'blur' }
        ],
        username: [
          { required: true, message: '请选输入连接用户名', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请选输入连接密码', trigger: 'blur' }
        ],
        port: [
          { required: true, message: '请选输入端口号', trigger: 'blur' }
        ],
        remark: [
          { required: true, message: '请选输入备注', trigger: 'blur' }
        ]
      }
    };
  },

  watch: {
    data: {
      immediate: true,
      handler (val) {
        this.model = resolveActionModel(val[0]);
      },
      deep: true
    },
  },

  methods: {
    handleCancel (formName) {
      this.$refs[formName].resetFields();
      this.$emit('close');
    },
    handleSubmit (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          try {
            const url = this.model.id === undefined
              ? addDb
              : updateDb;
            let params = {
              ...this.model
            };
            params.port = parseInt(this.model.port);
            const res = await this.$http.post(url, params);
            if (res.code === 0) {
              this.$emit('change', this.model);
              this.$emit('close');
              if (this.model.id === undefined) {
                this.$message.success('创建成功');
                this.$emit('finder-refresh');
              } else {
                this.$message.success('更新成功');
              }
            } else {
              this.$message.error(res.msg);
            }
          } catch (err) {
            console.log('error', err);
          }
        } else {
          return false;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.connector-detail {
  padding: 10px 20px;
}
</style>
